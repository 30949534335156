<template>
  <div id="policy-container">
    <div class="title">
      Sözleşmeler <span v-if="result.TotalCount != 0">({{ result.TotalCount }})</span>
    </div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Sözleşmeler</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-button class="add" @click="setItem(null)"><i class="icon-plus"></i></el-button>
        <div v-if="multipleSelection.length > 0">{{ multipleSelection.length }} adet veri seçildi</div>
        <el-button class="delete" @click="deleteSelected()" v-if="multipleSelection.length > 0"><i class="icon-delete"></i></el-button>
        <!-- <el-button class="print"><i class="icon-print"></i>export</el-button>
        <el-button class="import"><i class="icon-import"></i>import</el-button> -->
      </div>
    </div>
    <div class="all-data">
      <div class="list-table">
        <el-table ref="multipleTable" :data="policyList" @selection-change="handleSelectChange">
          <el-table-column sortable type="selection" align="center" style="text-center"> </el-table-column>
          <el-table-column sortable prop="Policy" label="SIRA NO" width="110">
            <template slot-scope="scope">
              {{ scope.row.OrderNo }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="title" label="BAŞLIK">
            <template slot-scope="scope">
              {{ scope.row.Title }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="type" label="SÖZLEŞME TİPİ" width="250">
            <template slot-scope="scope">
              {{ getEnumsDisplay("PolicyType", scope.row.Type) }}
            </template>
          </el-table-column>
          <el-table-column sortable label="KAYIT TARİHİ" width="145">
            <template slot-scope="scope">
              {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY") }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="" width="80">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display: block" @click="setItem(scope.row)">Detay</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided><span class="tex-danger" style="display: block"
                      @click="deleteFile(scope.row.ID)">Sil</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kayıt bulunamadı.</div>
            </div>
          </template>
        </el-table>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="30%" :close-on-click-modal="false">
        <div class="title">Sözleşme Ekle</div>
        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <div class="input-container">
            <label>Sıra No</label>
            <el-input v-model="form.OrderNo"></el-input>
            <span class="error">{{ $errorMessage("OrderNo", validation) }}</span>
          </div>

          <div class="input-container">
            <label>Başlık</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", validation) }}</span>
          </div>

          <label>İçerik</label>
          <div class="text-edit">
            <div id="toolbar">
              <select class="ql-size">
                <option value="small"></option>
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>

              <select class="ql-align">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </div>

            <quill-editor ref="myQuillEditor" :options="editorOption" v-model="form.Details"></quill-editor>
          </div>
          <span class="error">{{ $errorMessage("Details", validation) }}</span>
          <div class="input-container policy-select">
            <label>Sözleşme Tipi</label>
            <el-select v-model="form.Type" placeholder="Seçiniz..." clearable @clear="$store.state.appointments.filter.Status = null"
              v-if="getEnums != null">
              <el-option v-for="item in getEnums.find((x) => x.Title == 'PolicyType').Enums" :key="item.Key" :label="item.DisplayName"
                :value="item.Key"> </el-option>
            </el-select>
            <span class="error">{{ $errorMessage("Type", validation) }}</span>
          </div>
          <label>Dil Seçiniz</label>
          <el-select v-model="form.LanguageCode" style="width: 100%">
            <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode"
              style="display: flex; align-items: center">
              <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
              {{ item.Title }}
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addOrUpdate(null)"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        ID: null,
        Title: null,
        OrderNo: null,
        Details: null,
        Type: null,
        LanguageCode: null,
      },
      languageList: [],

      addUpdateDialog: false,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
        placeholder: "Buraya yazınız..",
      },

      multipleSelection: [],
    };
  },
  async beforeMount() {
    await this.getData();
    await this.getLanguageList();
    this.form.LanguageCode = this.languageList[0].LanguageCode;
  },
  computed: {
    filter() {
      return this.$store.getters.getPolicyFilter;
    },
    policyList() {
      return this.$store.getters.getPolicyList;
    },
    validation() {
      return this.$store.getters.getPolicyValidation;
    },
    result() {
      return this.$store.getters.getPolicyResult;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
  methods: {
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    async getData() {
      await this.$store.dispatch("getPolicyList", this.filter);
    },
    handleSelectChange(val) {
      this.multipleSelection = val;
    },
    async deleteFile(id) {
      await this.$store.dispatch("deleteOnePolicy", id);
    },

    setItem(item) {
      this.$store.commit("setPolicyValidation", []);
      if (item != null) {
        this.form.ID = item.ID;
        this.form.Title = item.Title;
        this.form.OrderNo = item.OrderNo;
        this.form.Details = item.Details;
        this.form.Type = item.Type;
      } else {
        this.form.ID = null;
        this.form.Title = null;
        this.form.OrderNo = null;
        this.form.Details = null;
        this.form.Type = null;
      }
      this.addUpdateDialog = true;
    },

    async addOrUpdate() {
      await this.$store.dispatch("addOrUpdatePolicy", this.form);

      if (this.validation.length == 0) {
        this.addUpdateDialog = false;
        this.form = {
          ID: null,
          Title: null,
          OrderNo: null,
          Details: null,
          Type: null,
        };
      }
    },
    async deleteSelected() {
      await this.$store.dispatch("deleteAllPolicy", this.multipleSelection);
    },
  },
};
</script>

<style lang="less">
#policy-container {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #54d0c8;

        i {
          background: white;
        }
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

        .el-table__row {
          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            i {
              height: 40px;
            }
          }
        }
      }
    }

    .el-dialog {
      width: 50%;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .el-icon-close:before {
        content: "";
      }

      .el-dialog__header {
        display: none;
      }

      padding: 0;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;

        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        input {
          background: #f8fafb;
          border: none;
        }

        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }

        .el-select {
          width: 100%;
          border-color: #8697a8;
        }

        .text-edit {
          #toolbar {
            padding: 9px;
            border: none;
            background-color: #f8fafb !important;
            border-radius: 5px 5px 0px 0px;
            font-family: "Roboto" !important;
          }

          .ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-active>.ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-container {
            min-height: 100px;
            border: none !important;
            background-color: #f8fafb;
            resize: both;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #8697a8;
          }
        }
      }

      .dialog-footer {
        width: 100%;

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          .btn-add {
            filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));

            width: 152px;
            background: #54d0c8;
            height: 40px;
            padding: 0;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: white;
            border: none;

            i {
              float: left;
            }
          }

          .btn-cancel {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            width: 110px;
            color: #54d0c8;
            background-color: #eae5f8;
            border: none;
          }
        }
      }
    }
  }
}</style>
